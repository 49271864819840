import { htmlDecode } from '@shein/common-function'

export const timeZone = () => {
  // 时区
  const date = new Date()
  const timeZone = date.getTimezoneOffset() / 60
  return `GMT${timeZone > 0 ? '-' : '+'}${Math.abs(timeZone)}`
}

export const htmlDecodeUtil = ({ text, isHtml = true }) => {
  return htmlDecode({ text, isHtml })
}

const { PUBLIC_CDN, GB_cssRight } = gbCommonInfo
const prefix = PUBLIC_CDN + '/she_dist/images/user/renew_dialog'
// todo 名字替换
const IMAGES = {
  giftImage: GB_cssRight ? 'renew-gift-ar-586bb38069' : 'renew-gift-2cb910ee67',
  bgTopImage: GB_cssRight
    ? 'renew-bg-top-ar-634720435e'
    : 'renew-bg-top-8060b2bdfc',
  closeImage: 'renew-close-7c4ceb6101',
  renewMainBg: 'renew-main-bg-28170d7f6f',
  renewMainBgCoupon: 'renew-main-bg-coupon-82b911f821'
}

const RENEW_IMG = {}
for (const [key, value] of Object.entries(IMAGES)) {
  RENEW_IMG[key] = `${prefix}/${value}.png`
}

export { RENEW_IMG }
